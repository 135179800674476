<template>
  <div v-if="$route.params.name === 'impressum'">
    <h2>Impressum</h2>
    <p>
      Anschrift:<br>
      Carmen Westphalen<br>
      Blankeneser Hauptstrasse 49<br>
      22587 Hamburg
    </p>
    <p>
      Telefon:<br>
      040-8305001
    </p>
    <p>
      Email:<br>
      info@privatespa-hamburg.de
    </p>
    <p>
      Handwerkskammer Hamburg Betriebsnr.: 2930909020
    </p>
    <p>
      Bildnachweise: <br>
      MALU WILZ BEAUTÈ GmbH
    </p>
  </div>
  <div v-else-if="$route.params.name === 'datenschutz'">
    <h2>Datenschutz</h2>
    <p>
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
      Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      <br>
      Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
      Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.
      Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
      <br>
      Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
      Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </p>
    <h3>Cookies</h3>
    <p>
      Die Internetseiten verwenden teilweise so genannte Cookies.
      Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
      Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
      Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
      <br>
      Die meisten der von uns verwendeten Cookies sind so genannte "Session-Cookies".
      Sie werden nach Ende Ihres Besuchs automatisch gelöscht.
      Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen.
      Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
      <br>
      Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren.
      Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
    </p>
    <h3>Server-Log-Files</h3>
    <p>
      Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files,
      die Ihr Browser automatisch an uns übermittelt. Dies sind:
    </p>
    <ul>
      <li>Browsertyp/ Browserversion</li>
      <li>verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>Uhrzeit der Serveranfrage</li>
    </ul>
    <p>
      Diese Daten sind nicht bestimmten Personen zuordenbar.
      Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
      Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
    </p>
    <h3>Kontaktformular</h3>
    <p>
      Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert.
      Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
    </p>
    <h3>Datenschutzerklärung für die Nutzung von Google Analytics</h3>
    <p>
      Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
      Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
      <br>
      Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
      Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
      <br>
      Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der <a href="https://support.google.com/analytics/answer/6004245?hl=de"> Datenschutzerklärung</a> von Google.
    </p>
    <h3>Browser Plugin</h3>
    <p>
      Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
      wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
      Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
      indem Sie das unter dem folgenden Link verfügbare <a href="https://tools.google.com/dlpage/gaoptout?hl=de">Browser-Plugin</a> herunterladen und installieren.
    </p>
    <h3>Widerspruch gegen Datenerfassung</h3>
    <p>
      Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken.
      Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: Google Analytics deaktivieren.
    </p>
    <h3>Datenschutzerklärung für die Nutzung von Facebook-Plugins (Like-Button)</h3>
    <p>
      Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA, integriert.
      Die <a href="http://developers.facebook.com/docs/plugins/">Facebook-Plugins</a> erkennen Sie an dem Facebook- Logo oder dem "Like-Button" ("Gefällt mir") auf unserer Seite.
      <br>
      Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
      Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben.
      Wenn Sie den Facebook "Like-Button" anklicken während Sie in Ihrem Facebook- Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken.
      Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen.
      Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten.
      Weitere Informationen hierzu finden Sie in der <a href="http://de.facebook.com/policy.php">Datenschutzerklärung</a> von Facebook.
      <br>
      Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook- Benutzerkonto aus.
    </p>
    <h3>Widerspruch Werbe-Mails</h3>
    <p>
      Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen.
      Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
    </p>
  </div>
  <div v-else-if="$route.params.name === 'haftungsausschluss'">
    <h2>Haftungsausschluss</h2>
    <p>
      Die bereitgestellten Informationen auf dieser Webseite wurden sorgfältig geprüft und werden regelmäßig aktualisiert.
      Jedoch kann keine Garantie dafür übernommen werden, dass alle Angaben zu jeder Zeit vollständig, richtig und in letzter Aktualität dargestellt sind.
      Dies gilt insbesondere für alle Verbindungen ("Links") zu anderen Websites, auf die direkt oder indirekt verwiesen wird. Alle Angaben können ohne vorherige Ankündigung geändert, entfernt oder ergänzt werden.
    </p>
    <h3>Links:</h3>
    <p>
      Mit dem Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden, dass man durch die Anbringung eines Links die Inhalte der gelinkten Seite ggf. mit zu verantworten hat.
      Dies kann - so das Landgericht - nur dadurch verhindert werden, dass man sich ausdrücklich von diesen Inhalten distanziert.
      Auf dieser Website befinden sich Linkes zu anderen Inhalten im Internet.
      Für diese Links gilt: Wir betonen ausdrücklich, dass wir keinerlei Einfluss auf die Gestaltung und die Inhalte der gelinkten Seiten/Inhalte haben.
      Deshalb distanzieren wir uns hiermit ausdrücklich von allen Inhalten aller gelinkten Seiten auf dieser Website.
      Diese Erklärung gilt für alle Seiten dieser Website, wo Links oder Banner auf externe Inhalte verweisen.
    </p>
  </div>
  <div v-else>
    <h3>Die Seite wurde nicht gefunden</h3>
  </div>
</template>

<script>
export default {
  name: "Content"
}
</script>

<style scoped>
div {
  padding: 0.5rem;
}
</style>