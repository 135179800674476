<template>
  <router-link :to="{ name: 'group', params: { group: name} }">
    <div>
      {{ name }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Group",

  props: [
      'name'
  ]
}
</script>

<style scoped>
a {
  text-transform: capitalize;
  text-decoration: none;
  color: #313231;
  font-size: xx-large;
  font-weight: bold;
  width: 100%;
}
div {
  width: 100%;
  background-color: #839285;
  margin-bottom: 0.5rem;
  padding: 3rem 0.5rem;
  box-sizing: border-box;
  background-image: url("~@/assets/privatespaheader.jpg");
  background-position: center;
  background-size: cover;
  border: 0.15rem solid #313231;
}
</style>