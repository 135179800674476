<template>
  <h1>{{ $route.params.group }}</h1>
  <div class="container" v-for="category in categories" :key="category.id">
    <h2>{{ category.name }}</h2>
    <p v-if="category.info !== undefined">
      {{ category.info }}
    </p>
    <div class="container" v-for="product in category.products" :key="product.id">
      <h3>{{ product.name }}</h3>
      <p v-if="product.description !== undefined">{{ product.description }}</p>
      <div class="options" v-for="option in product.options" :key="option.type">
        <div>
          <p v-if="product.options.length > 1">{{ option.name }}</p>
          <p>Dauer: {{ option.duration}} Preis: {{ option.price }}</p>
        </div>
        <div class="buttons" v-if="product.standalone">
          <router-link class="button" :to="{ name: 'upselling', query: {group: $route.params.group, category: $route.params.category, service_id: product.id, service_option: option.type, mode: 'coupon'}}">
            Als Gutschein
          </router-link>
          <router-link class="button" :to="{ name: 'upselling', query: {group: $route.params.group, category: $route.params.category, service_id: product.id, service_option: option.type, mode: 'book'}}">
            Termin buchen
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductList",

  data: function () {
    return {
      categories: []
    }
  },

  methods: {
    chooseCategories: function() {
      if (this.$route.params.group === 'teenager') {
        this.categories = [
          {
            name: "Gesichtsbehandlungen",
            id: "gesicht",
            new: false,
            products: [
              {
                id: "young-care-1",
                name: "Young Care",
                description: "Intensive Reinigungsbehandlung für junge, unreine Haut. " +
                    "Der Behandlungsschwerpunkt liegt hier bei der intensiven Ausreinigung. " +
                    "Moderne Wirkstoff-Kombinationen garantieren nicht nur eine schonende Behandlung der entzündeten Haut, " +
                    "sondern geben bei regelmäßiger Anwendung eine Lösung für eine dauerhafte Verbesserung des Hautbildes.",
                standalone: true,
                options: [
                  {
                    name: "Standard",
                    duration: "ca. 60min",
                    price: "57,50€",
                    type: "Standard"
                  },
                  {
                    name: "Kurangebot (10 + 2 Gratis!)",
                    duration: "ca. 60min",
                    price: "575,00€",
                    type: "Multiple"
                  }
                ]
              },
              {
                id: "young-moisturzizer-2",
                name: "Young Moisturizer",
                description: "Natürliche Pflanzenextrakte, Vitamine und ein Feuchtigkeitskomplex verleihen der Haut neue Frische und einen strahlenden Teint. " +
                    "Die Haut wird sanft gereinigt, mit einem Konzentrat und einer Maske intensiv mit Feuchtigkeit versorgt und mit der abschließenden Pflege sanft gepflegt und geschützt.",
                standalone: true,
                options: [
                  {
                    name: "Standard",
                    duration: "ca. 40 min",
                    price: "44,80€",
                    type: "Standard"
                  }
                ]
              }
            ]
          },
          {
            name: "Extra's",
            id: "extras",
            new: false,
            info: "Nur in Verbindung mit einer Gesichtsbehandlung",
            products: [
              {
                id: "extra-1",
                name: "Augenbrauenkorrektur",
                standalone: false,
                options: [
                  {
                    name: "Standard",
                    duration: "ca. 5 min",
                    price: "8,80€",
                    type: "Standard"
                  }
                ]
              },
              {
                id: "extra-2",
                name: "Augenbrauen färben",
                standalone: false,
                options: [
                  {
                    name: "Standard",
                    duration: "ca. 5 min",
                    price: "8,80€",
                    type: "Standard"
                  }
                ]
              },
              {
                id: "extra-3",
                name: "Wimpern färben",
                standalone: false,
                options: [
                  {
                    name: "Standard",
                    duration: "ca. 5 min",
                    price: "10,70€",
                    type: "Standard"
                  }
                ]
              },
              {
                id: "extra-4",
                name: "Augenbrauen und Wimpern färben",
                standalone: false,
                options: [
                  {
                    name: "Standard",
                    duration: "ca. 5 min",
                    price: "16,60€",
                    type: "Standard"
                  }
                ]
              }
            ]
          }
        ]
      } else if (this.$route.params.group === 'ladies') {
        this.categories = [
          {
            name: "Special's",
            id: "specials",
            new: false
          },
          {
            name: "Powerbehandlungen",
            id: "power",
            new: true
          },
          {
            name: "Gesichtsbehandlungen",
            id: "gesicht",
            new: false
          },
          {
            name: "Körperbehandlungen",
            id: "koerper",
            new: false
          },
          {
            name: "Extra's",
            id: "extras",
            new: false
          }
        ]
      } else if (this.$route.params.group === 'gentlemen') {
        this.categories = [
          {
            name: "Gesichtsbehandlungen",
            id: "gesicht",
            new: false
          },
          {
            name: "Körperbehandlungen",
            id: "koerper",
            new: false
          },
          {
            name: "Extra's",
            id: "extras",
            new: false
          }
        ]
      }
    }
  },

  mounted() {
    this.chooseCategories()

    this.$watch(
        () => this.$route.params.group, this.chooseCategories
    )
  }
}
</script>

<style scoped>
div.container {
  border-top: 2px solid #313231;
  margin-top: 0.5rem;
}
div.options {
  border-top: 1px solid #839285;
  margin-top: 0.5rem
}
div.buttons {
  display: flex;
  width: 100%;
}
div.buttons .button {
  margin-right: 0.25rem;
  width: 50%;
}
div.buttons .button:last-of-type {
  width: 50%;
  margin-left: 0.25rem;
  margin-right: 0;
}
</style>