<template>
  <h1 v-if="$route.query.mode === 'coupon'">Gutschein kaufen</h1>
  <h1 v-else>Termin vereinbaren</h1>
  <a class="button" href="tel:+4915780450214">Direkt Anrufen</a>
  <h2>Deine Auswahl</h2>
  <div>
    <p>{{ $route.query.service_id + " " + $route.query.service_option}}</p>
    <p>Preis: X.XX €</p>
  </div>
  <div v-if="$route.query.extra !== undefined">
    <p>{{ $route.query.extra }}</p>
    <p>Preis: X.XX €</p>
  </div>
  <router-link class="button return" :to="{name: 'upselling', query: {group: $route.query.group, category: $route.query.category, service_id: $route.query.service_id, service_option: $route.query.service_option, mode: 'coupon'}}">Zurück zu den Extra's</router-link>
</template>

<script>
export default {
  name: "PlaceOrder"
}
</script>

<style scoped>

</style>