<template>
  <div class="nav-button" @click="sendButtonClickEvent">
    <slot></slot>
    <p>{{ name }}</p>
  </div>
</template>

<script>
export default {
  name: "NavigationButton",

  props: [
    'name',
    'icon'
  ],

  methods: {
    sendButtonClickEvent: function () {
      this.$emit('button-click')
    }
  }
}
</script>

<style scoped>
.nav-button {
  color: inherit;
  text-decoration: none;
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: xx-small;
  cursor: pointer;
}
p {
  margin: 0;
}
</style>