import { createWebHistory, createRouter } from 'vue-router';
import HelloWorld from "@/components/HelloWorld";
import Content from "@/components/content/Content";
import GroupPicker from "@/components/services/GroupPicker";
import UpsellingPicker from "@/components/services/UpsellingPicker";
import PlaceOrder from "@/components/services/PlaceOrder";
import ProductList from "@/components/product-list/ProductList";

const routes = [
    {
        path: "/",
        name: "home",
        component: HelloWorld,
    },
    {
        path: "/:group",
        name: "group",
        component: ProductList
    },
    {
        path: "/picker",
        name: "group_picker",
        component: GroupPicker
    },
    {
        path: "/upselling",
        name: "upselling",
        component: UpsellingPicker
    },
    {
        path: "/order",
        name: "place_order",
        component: PlaceOrder
    },
    {
        path: "/content/:name",
        name: "content",
        component: Content
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;