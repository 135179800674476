<template>
  <div class="disclaimer">
    <p>Aufgrund der aktuellen Corona-Verordnung finden zur Zeit leider keine Behandlungen statt!</p>
  </div>
  <router-link class="button" :to="{ name: 'group_picker'}">Zu den Behandlungen</router-link>
  <h2>Mobiler Kosmetikservice</h2>
  <p>
    Mit meinem mobilen Kosmetik & Wellness Service komme ich zu Ihnen!<br>
    Jeder braucht in seinem Alltag manchmal eine Auszeit.<br>
    In der es sich um nichts anderes dreht, als das eigene Wohlbefinden.<br>
    Oft hat man nicht die Möglichkeit, sich in einem Kosmetikstudio verwöhnen zu lassen ...<br>
    ... sei es aus zeitlichen Gründen, Krankheit, Kinder oder dem Alter.<br>
    Ich komme zu Ihnen, bringe alles mit und sobald die Musik beginnt können Sie entspannen.
  </p>
  <img src="@/assets/about-me.jpg">
  <p>
    In meiner langjährigen Tätigkeit als Kosmetikerin habe ich viel über die individuellen Bedürfnisse des Einzelnen erfahren.<br>
    Es ist mir wichtig, dass die Haut meiner Kunden optimal versorgt wird.<br>
    Ich möchte das meine Kunden sich täglich wohl in ihrer Haut fühlen und die regelmäßigen Kosmetikbehandlungen wie einen Kurzurlaub empfinden.
  </p>
</template>

<script>
export default {
  name: 'HelloWorld'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
div.disclaimer {
  background-color: #839285;
  color: #313231;
  border: 0.15rem solid #313231;
  margin-bottom: 0.5rem;
  font-weight: bold;
  padding: 0 0.5rem;
}
img {
  width: 100%;
  border: 0.15rem solid #313231;
  box-sizing: border-box;
}
</style>
