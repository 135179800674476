<template>
  <div>
    <h3>{{ name }}</h3>
    <p>Preis {{ price }}</p>
    <router-link class="button" :to="{ name: 'place_order', query: {group: $route.query.group, category: $route.query.category, service_id: $route.query.service_id, service_option: $route.query.service_option, mode: $route.query.mode, extra: name }}">
      Extra hinzufügen
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Extras",

  props: [
      "name",
      "price"
  ]
}
</script>

<style scoped>
  div {

  }
</style>