<template>
  <router-link class="button action" :to="{ name: 'place_order', query: {group: $route.query.group, category: $route.query.category, service_id: $route.query.service_id, service_option: $route.query.service_option, mode: $route.query.mode }}">
    Ohne Extras fortfahren
  </router-link>
  <h1>Extras auswählen</h1>
  <Extras v-for="extra in extras" :key="extra.name" :name="extra.name" :price="extra.price"/>
  <router-link class="button return" :to="{ name: 'group', params: { group: $route.query.group} }">
    Zurück zu den Behandlungen
  </router-link>
</template>

<script>
import Extras from "@/components/services/components/Extras";
export default {
  name: "UpsellingPicker",
  components: {Extras},
  data: function () {
    return {
      extras: [
        {
          name: "Augenbrauenkrorrektur",
          price: "8,80 €"
        },
        {
          name: "Augenbrauen färben",
          price: "8,80 €"
        },
        {
          name: "Wimpern färben",
          price: "10,70 €"
        },
        {
          name: "Augenbrauen und Wimpern färben",
          price: "16,60 €"
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>