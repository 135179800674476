<template>
  <div class="nav-list">
    <div v-for="item in content.items" :key="item.id" @click="sendButtonClickEvent(item.id)">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationList",

  props: [
      'content'
  ],

  methods: {
    sendButtonClickEvent: function (id) {
      this.$emit("list-item-click", id)
    }
  }
}
</script>

<style scoped>
div.nav-list {
  position: fixed;
  bottom: 3.5rem;
  width: 100%;
}
div.nav-list div {
  display: block;
  background-color: #313231;
  width: 100%;
  padding: 0.75rem;
  border-top: 2px solid #839285;
  color: inherit;
  text-decoration: none;
  font-size: large;
  cursor: pointer;
  box-sizing: border-box;
}
</style>