<template>
  <nav>
    <NavigationButton name="Startseite" @button-click="updateNavList('startseite')">
      <img class="icon" src="@/assets/icons/home.svg">
    </NavigationButton>
    <NavigationButton name="Behandlungen" @button-click="updateNavList('behandlungen')">
      <img class="icon" src="@/assets/icons/products.svg">
    </NavigationButton>
    <NavigationButton name="Anrufen" @button-click="updateNavList('anrufen')">
      <img class="icon" src="@/assets/icons/phone.svg">
    </NavigationButton>
    <NavigationButton name="Info" @button-click="updateNavList('info')">
      <img class="icon" src="@/assets/icons/info.svg">
    </NavigationButton>
    <NavigationList :content="navigationList" @list-item-click="listItemClick"/>
  </nav>
</template>

<script>
import NavigationList from "@/components/navigation/NavigationList";
import NavigationButton from "@/components/navigation/NavigationButton";
import router from "@/router";
export default {
  name: "Navigation",
  components: {NavigationButton, NavigationList},

  data: function () {
    return {
      navigationList: {
        identifier: "empty",
        items: []
      },
      behandlungen: [
        { name: "Ladies", id: "ladies" },
        { name: "Gentlemen", id: "gentlemen" },
        { name: "Teenager", id: "teenager" }
      ],
      info: [
        { name: "Impressum", id: "impressum" },
        { name: "Datenschutz", id: "datenschutz" },
        { name: "Haftungsausschluss", id: "haftungsausschluss" }
      ]
    }
  },

  methods: {
    listItemClick: function (id) {
      if (this.navigationList.identifier === "behandlungen") {
        router.push({ name: 'group', params: { group: id}})
      } else if (this.navigationList.identifier === "info") {
        router.push({ name: 'content', params: { name: id} })
      }
      this.clearNavList()
    },

    updateNavList: function(buttonName) {
      if (buttonName === "startseite") {
        router.push({ name: "home"})
        this.clearNavList()
      } else if (buttonName === "anrufen") {
        window.open("tel:+49408305001");
        this.clearNavList()
      } else if (buttonName === "behandlungen") {
        if (this.navigationList.identifier === "behandlungen") {
          this.clearNavList()
        } else {
          this.setNavList("behandlungen", this.behandlungen)
        }
      } else if (buttonName === "info") {
        if (this.navigationList.identifier === "info") {
          this.clearNavList()
        } else {
          this.setNavList("info", this.info)
        }
      }
    },

    setNavList: function (identifier, items) {
      this.navigationList.identifier = identifier
      this.navigationList.items = items
    },

    clearNavList: function () {
      this.setNavList("empty", [])
    }
  }
}
</script>

<style>
nav {
  background-color: #313231;
  display: grid;
  position: fixed;
  bottom: 0;
  height: 3.5rem;
  width: 100%;
  grid-template-rows: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  border-top: 2px solid #839285;
  box-sizing: border-box;
}

.icon {
  height: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}
</style>