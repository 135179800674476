<template>
  <Header />
  <div class="content">
    <router-view></router-view>
  </div>
  <Navigation />
</template>

<script>
import Header from './components/Header.vue'
import Navigation from "@/components/navigation/Navigation";

export default {
  name: 'App',
  components: {
    Navigation,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #839285;
  background-color: #FFF8F4;
  padding-bottom: 3.5rem;
}
body {
  padding: 0;
  margin: 0;
}
h1 {
  font-size: xx-large;
  text-transform: capitalize;
  color: #313231;
}
a.button {
  width: 100%;
  background-color: #313231;
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: large;
  box-sizing: border-box;
  padding: 0.5rem;
  font-weight: bold;
}
a.return {
  margin-top: 0.5rem;
  border: 2px solid #313231;
  background-color: #839285;
  color: #313231;
  font-size: medium;
}

div.content {
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
}
</style>
