<template>
  <h1>Übersicht</h1>
  <Group name="ladies"/>
  <Group name="gentlemen"/>
  <Group name="teenager"/>
  <router-link class="button return" :to="{ name: 'home'}">Zurück zur Startseite</router-link>
</template>

<script>
import Group from "@/components/services/components/Group";
export default {
  name: "GroupPicker",
  components: {Group}
}
</script>

<style scoped>

</style>