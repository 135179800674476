<template>
<header>
  <router-link class="logo" :to="{ name: 'home'}">
    <img class="logo" src="@/assets/privatespa.jpg">
  </router-link>
</header>
</template>

<script>
export default {
  name: "Header.vue"
}
</script>

<style scoped>
header {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: auto auto;
  background-color: #839285;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid #313231;
}
.logo {
  grid-column: 2/3;
  grid-row: 1;
}
.logo img {
  width: 100%;
  margin: 0 auto;
}
div.phone {
  grid-column: 3/4;
  grid-row: 1;
  justify-self: center;
  align-self: center;
  border-radius: 100%;
  background-color: #313231;
  height: 4rem;
  width: 4rem;
  padding: 0.75rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
div.mail {
  grid-column: 1/2;
  grid-row: 1;
  justify-self: center;
  align-self: center;
  border-radius: 100%;
  background-color: #313231;
  height: 4rem;
  width: 4rem;
  padding: 0.75rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
div.mail a img, div.phone a img {
  max-width:100%;
  max-height:100%;
}
</style>